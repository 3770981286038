<template>
    <div class="pagination" v-if="!isLoading">
        <div class="count-container">
            <div class="count-message">
                第 {{ getMinIndex }} - {{ getMaxIndex }} 筆， 共
                {{ $numberWithComma(dataCount) + ' 筆' }}
            </div>
            <span>&nbsp; / &nbsp;</span>
            <div class="per-page">
                每頁顯示
                <Dropdown
                    :width="73"
                    name="perPage"
                    :options="perPageOptions"
                    v-model="perPageValue"
                />
                筆
            </div>
        </div>
        <div class="page-click">
            <div
                v-if="currentPage > 1"
                class="page page-last icon rotate"
                @click="goToPage(1), setGoToPageGa('First')"
            ></div>
            <div
                v-if="currentPage > 1"
                class="page page-next icon rotate"
                @click="goToPage(currentPage - 1), setGoToPageGa('Previous')"
            ></div>

            <a
                class="page"
                v-for="i in getPaginationSet()"
                :key="i"
                :class="getPageClass(i)"
                @click="goToPage(i)"
            >
                <div>{{ i }}</div>
            </a>
            <div
                v-if="totalPage > pagesPerView && currentPage !== totalPage"
                class="page page-next icon"
                @click="goToPage(currentPage + 1), setGoToPageGa('Next')"
            ></div>
            <div
                v-if="totalPage > pagesPerView && currentPage !== totalPage"
                class="page page-last icon"
                @click="goToPage(totalPage), setGoToPageGa('Last')"
            ></div>
        </div>
    </div>
</template>

<script>
import Dropdown from '@/components/Dropdown'
export default {
    name: 'Pagination',
    emits: ['go-to-page', 'change-per-page'],
    components: {
        Dropdown
    },
    props: {
        isLoading: {
            type: Boolean,
            default: false
        },
        currentPage: {
            type: Number,
            default: 1
        },
        totalPage: { type: Number },
        dataCount: { type: Number }
    },
    computed: {
        getMinIndex: function () {
            const index =
                this.dataCount === 0
                    ? 0
                    : this.currentPage * this.parseIntPerPage -
                      this.parseIntPerPage +
                      1
            return this.$numberWithComma(index)
        },
        getMaxIndex: function () {
            let index
            if (this.currentPage * this.parseIntPerPage > this.dataCount)
                index = this.dataCount
            else index = this.currentPage * this.parseIntPerPage
            return this.$numberWithComma(index)
        },
        perPageOptions: function () {
            return [
                {
                    label: '10',
                    value: '10'
                },
                {
                    label: '20',
                    value: '20'
                },
                {
                    label: '50',
                    value: '50'
                },
                {
                    label: '100',
                    value: '100'
                },
                {
                    label: '300',
                    value: '300'
                }
            ]
        },
        parseIntPerPage: function () {
            return parseInt(this.perPageValue)
        }
    },
    methods: {
        getPaginationSet: function () {
            let pageSet = []
            let startPage = ''
            let endPage = ''

            if (this.currentPage >= this.pagesPerView) {
                startPage = this.currentPage - (this.pagesPerView - 1)
            } else {
                startPage = 1
            }

            if (this.totalPage < startPage + (this.pagesPerView - 1)) {
                endPage = this.totalPage
            } else {
                endPage = startPage + (this.pagesPerView - 1)
            }

            for (let n = startPage; n < endPage + 1; n++) {
                pageSet.push(n)
            }
            this.startPage = startPage

            return pageSet
        },
        getPageClass: function (i) {
            if (i == this.currentPage) return 'current-page'
        },
        goToPage: function (page) {
            this.$emit('go-to-page', page)
        },
        setGoToPageGa: function (label) {
            this.$setGaEvent(`clickGo${label}Page`, 'click-Pagination')
        }
    },
    watch: {
        perPageValue: function () {
            this.$emit('go-to-page', 1)
            this.$emit('change-per-page', this.parseIntPerPage)
            this.$setGaEvent(
                `${this.perPageValue}PerPageValue`,
                'click-Pagination'
            )
        }
    },
    data() {
        return {
            startPage: '',
            pagesPerView: 6,
            perPageValue: '10'
        }
    }
}
</script>

<style lang="scss" scoped>
.pagination {
    width: 100%;
    margin: 0 5px 20px 0;
    @media screen and (max-width: 576px) {
        margin: 0 5px 0 0;
    }
}

.page-click {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 12px;
    color: $sixth-black;

    .rotate {
        transform: rotate(180deg);
    }

    .page {
        min-width: 20px;
        line-height: 14px;
        padding-top: 3px;
        padding-bottom: 3px;

        text-align: center;
        border-radius: 3px;
        margin-right: 5px;
        cursor: pointer;

        &.page-next {
            height: 4px;
        }
        &.page-last {
            height: 4px;
        }
        &:not(.current-page):hover {
            color: $eighth-grey;
            div {
                padding-top: 0;
                margin-bottom: -3px;
            }
        }

        &.current-page {
            background: $primary-red;
            color: $primary-white;
        }

        &:last-child {
            margin-right: 0px;
        }
    }
}
.count-container {
    display: flex;
    justify-content: flex-end;
    font-size: 14px;
    color: $sixth-black;
    text-align: right;
    .per-page {
        display: flex;
        justify-content: flex-end;
        height: 40px;
    }
    .count-message {
        margin-bottom: 10px;
    }

    @media screen and (max-width: 576px) {
        padding-top: 0px;
        margin-bottom: 0 !important;
        display: block;
        span {
            display: none;
        }
    }
}
:deep(.input-container) {
    &:last-child {
        margin-right: 3px;
    }
    margin: -2px 0px 0px 2px;
}

:deep(.select-container) {
    padding-inline: 3px;

    .select {
        padding: 12px 0px 12px 8px;
        text-align: left;
        max-height: 16px;
        line-height: 16px;
        background-position: 92%;
        background-color: $primary-white;
        border: 1px solid $fifth-black;
    }
    ::-webkit-scrollbar {
        width: 5px;
    }
    ::-webkit-scrollbar-thumb {
        background-color: $scrollbar-thumb-grey;
        border-radius: 10px;
    }
    scrollbar-width: thin;
    scrollbar-color: $scrollbar-thumb-grey transparent;

    .textbox-container {
        overflow: hidden;
    }
    .option-container {
        height: 100px;
        margin-top: -3px;
        .option {
            min-height: 30px;
        }
        @media screen and (max-width: 576px) {
            height: 80px;
        }
    }
}
</style>
